import { Spinner } from 'app/components/Loader';
import { Tooltip } from 'app/components/Tooltip';
import classNames from 'classnames';
import type React from 'react';

export type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'invisible';
  size?: 'sm' | 'md' | 'lg';
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  tooltip?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const buttonClassNames = {
  primary: 'text-white bg-gray-700',
  secondary: 'text-gray-700 bg-white border-[1.5px] border-carbon-200',
  invisible: 'text-gray-700 bg-transparent hover:bg-gray-100 focus:bg-gray-100',
} as const;

const buttonClassNamesDisabled = {
  primary: 'text-gray-400 bg-gray-200',
  secondary: 'text-gray-400 bg-white border-[1.5px] border-carbon-200',
  invisible: 'text-gray-700 bg-transparent',
} as const;

type TooltipWrapperProps = {
  tooltip?: string;
  children: React.ReactNode;
};
const TooltipWrapper = ({ children, tooltip }: TooltipWrapperProps) => {
  if (!tooltip) return children;
  return (
    <Tooltip.Default top="top-9" trigger="hover" content={tooltip}>
      {children}
    </Tooltip.Default>
  );
};
export const Button = (props: ButtonProps) => {
  const { className, loading, ...rest } = props;
  const variant = props.variant || 'primary';

  let sizeClassName = '';

  switch (props.size) {
    case 'sm': {
      const px = className?.includes('px') ? '' : 'px-1';
      const h = className?.includes('h') ? '' : 'h-6';
      sizeClassName = `${h} ${px} text-xs`;
      break;
    }
    case 'lg': {
      const px = className?.includes('px') ? '' : 'px-4';
      const py = className?.includes('py') ? '' : 'py-2.5';
      const h = className?.includes('h') ? '' : 'h-11';
      sizeClassName = `${py} ${px} ${h} text-lg`;
      break;
    }
    default: {
      const px = className?.includes('px') ? '' : 'px-3';
      const py = className?.includes('py') ? '' : 'py-1.5';
      const h = className?.includes('h') ? '' : 'h-9';

      sizeClassName = `${h} ${px} ${py} gap-2`;
      break;
    }
  }
  return (
    <TooltipWrapper tooltip={props.tooltip}>
      <button
        type="button"
        className={classNames(
          props.className,
          'flex flex-row align-center items-center justify-center rounded-md text-sm font-medium whitespace-nowrap',
          sizeClassName,
          props.disabled
            ? buttonClassNamesDisabled[variant]
            : buttonClassNames[variant],
          props.disabled && 'cursor-not-allowed',
        )}
        {...rest}
      >
        {loading && <Spinner height="h-5" width="w-5" />}
        {props.children}
      </button>
    </TooltipWrapper>
  );
};
