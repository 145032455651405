// This component wraps next/link and automatically prefixes the href with the either the current locale or a locale the user is switching to as necessary.
// cf https://next-intl-docs.vercel.app/docs/routing/navigation#apis
import { createNavigation } from 'next-intl/navigation';
import { defineRouting } from 'next-intl/routing';

import { SUPPORTED_LOCALES } from '../i18n/request';

export const routing = defineRouting({
  locales: SUPPORTED_LOCALES,
  localePrefix: 'always', // default
  defaultLocale: 'en',
});

export const { Link, redirect, usePathname, useRouter } =
  createNavigation(routing);
