'use client';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

import FullPageError from './components/Error/FullPageError';

const errorSwap = [
  {
    title: 'invalid_request',
    message: 'invitation not found or already used',
    swapTitle: 'Invalid invitation',
    swapMessage:
      'The invitation link you are trying to use is invalid. It might have expired or already been used.',
  },
];

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    // Global errors will always go through this path and have the same stack trace.
    // Make sure to group them by what the error actually is, rather than the stack trace.
    Sentry.withScope((scope) => {
      // Docs:
      // https://docs.sentry.io/platforms/javascript/usage/sdk-fingerprinting
      scope.setFingerprint([error.name, error.message]);

      Sentry.captureException(`${error.name}: ${error.message}`);
    });
  }, [error]);

  let errorTitle = error.name;
  let errorMessage = error.message;

  const swapped = errorSwap.find(
    (swap) => swap.title === error.name && swap.message === error.message,
  );

  if (swapped) {
    errorTitle = swapped.swapTitle;
    errorMessage = swapped.swapMessage;
  }
  return (
    <html>
      <body>
        <FullPageError title={errorTitle} message={errorMessage} />
      </body>
    </html>
  );
}
