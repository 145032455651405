import classNames from 'classnames';

type SpinnerProps = {
  width?: string;
  height?: string;
  className?: string;
};
export const Spinner = ({
  width = 'w-8',
  height = 'h-8',
  className = '',
}: SpinnerProps) => (
  <div
    className={classNames(
      'animate-spin inline-block border-4 border-gray-300 border-r-transparent rounded-full',
      width,
      height,
      className,
    )}
    role="status"
  />
);

type FullPageVariant = {
  type: 'fullpage';
  text?: string;
};
type InlineVariant = {
  type: 'inline';
  text?: never;
};
type LoaderProps = {
  className?: string;
} & (FullPageVariant | InlineVariant);

const Loader = ({ className, type, text }: LoaderProps) => {
  switch (type) {
    case 'fullpage':
      return (
        <div
          className={classNames(
            'flex flex-col min-h-screen justify-center items-center',
            className,
          )}
        >
          <Spinner />
          <div className="text-center text-sm mt-6 h-5 text-gray-700">
            {text}
          </div>
        </div>
      );
    case 'inline':
      return (
        <div className={classNames('text-center w-full', className)}>
          <Spinner />
        </div>
      );
  }
};
export default Loader;
