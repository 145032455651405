import Icon, { type IconType } from '@carbonfact/ui-components/src/Icon';
import { Button } from 'app/components/Button';
import classNames from 'classnames';

type ButtonIconProps = {
  disabled?: boolean;
  onClick: () => void;
  tooltip?: string;
  icon: IconType;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  variant?: 'primary' | 'secondary' | 'invisible';
};

export const ButtonIcon = ({
  disabled,
  tooltip,
  onClick,
  className,
  icon,
  size = 'md',
  variant = 'secondary',
}: ButtonIconProps) => {
  let iconHeight = 20;
  let iconWidth = 20;

  switch (size) {
    case 'sm':
      iconHeight = 16;
      iconWidth = 16;
      break;
    case 'lg':
      iconHeight = 24;
      iconWidth = 24;
      break;
    default:
      iconHeight = 20;
      iconWidth = 20;
      break;
  }
  return (
    <Button.Default
      disabled={disabled}
      className={classNames(className, 'px-1')}
      size={size}
      tooltip={tooltip}
      variant={variant}
      onClick={onClick}
    >
      <Icon width={iconWidth} height={iconHeight} icon={icon} />
    </Button.Default>
  );
};
