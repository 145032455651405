import posthog from 'posthog-js';

export function capture(...args: Parameters<typeof posthog.capture>) {
  if (posthog) {
    posthog.capture(...args);
  }
}

export function userIdentify(...args: Parameters<typeof posthog.identify>) {
  if (posthog) {
    posthog.identify(...args);
  }
}

export function userReset() {
  if (posthog) {
    posthog.reset();
  }
}
