import { Button } from 'app/components/Button';
import { useTranslations } from 'next-intl';

interface FullPageErrorProps {
  title: string;
  message: string;
  errorCode?: string;
}

// Sometimes the translations are not available because error occurs in GlobalError, before the provider.
// TECH-DEBT: It can be improved so that translations are handled everywhere
const useTranslationsWithFallback = () => {
  try {
    return useTranslations();
  } catch (error) {
    return () => null;
  }
};

export default function FullPageError({
  title,
  message,
  errorCode,
}: FullPageErrorProps) {
  const t = useTranslationsWithFallback();
  return (
    <div className="h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto ">
        <main className="sm:flex">
          {errorCode && (
            <p className="text-4xl tracking-tight font-bold text-darkgraphite sm:text-5xl sm:tracking-tight">
              {errorCode}
            </p>
          )}
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl sm:tracking-tight">
                {title}
              </h1>
              <p className="mt-1 text-base text-gray-500">{message}</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <a href="/">
                <Button.Default>
                  {t('FullPageError.goBackHome') || 'Go back home'}
                </Button.Default>
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
